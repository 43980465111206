import React from 'react';

import Lottie from 'react-lottie';
import animationData from '../assets/Lottie/lottie-target.json'
import animationData2 from '../assets/Lottie/lottie-pen.json'
import animationData3 from '../assets/Lottie/lottie-contract.json'


const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
    }
    };
    const defaultOptionsPen = {
        loop: true,
        autoplay: true,
        animationData: animationData2,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
        };
    const defaultOptionsContract = {
        loop: true,
        autoplay: true,
        animationData: animationData3,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
        };
class Process extends React.Component {
  render() {
  	return (
        <section className="section bg-light" id="process">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <h1 className="section-title text-center">Notre processus de création de valeur</h1>
                    <div className="section-title-border margin-t-20"></div>
                    <p className="section-subtitle text-muted text-center font-secondary padding-t-30">Déroulement d'une collaboration avec CineStudio</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-6 text-center process-left-icon-1">
                    <i className="pe-7s-angle-right"></i>
                </div>
                <div className="col-lg-6 text-center process-left-icon-2">
                    <i className="pe-7s-angle-right"></i>
                </div>
            </div>
            <div className="row margin-t-50">
                <div className="col-lg-4 plan-line">
                    <div className="text-center process-box">
                        <Lottie options={defaultOptionsPen} height={100} width={100}/>
                        <h4 className="padding-t-15">Racontez nous votre histoire</h4>
                        <p className="text-muted">Discutons ensemble et cadrons le projet. Vous ne payez rien pour le moment.</p>
                    </div>
                </div>
                <div className="col-lg-4 plan-line">
                    <div className="text-center process-box">
                        <Lottie options={defaultOptionsContract} height={100} width={100}/>
                        <h4 className="padding-t-15">Proposition de service</h4>
                        <p className="text-muted">Ensuite, nous vous faisons une offre de service et il ne vous reste qu'à l'accepter pour que le projet démarre.</p>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="text-center process-box">
                        <Lottie options={defaultOptions} height={100} width={100}/>
                        <h4 className="padding-t-15">Recevez votre livrable de haute qualité dans les délais</h4>
                        <p className="text-muted">Vous bénéficiez de plusieurs rondes de révision. Satisfaction garantie !</p>
                    </div>
                </div>
                <div className="text-center mx-auto">
                <a href='#contact' className="btn btn-custom waves-light waves-effect margin-t-50">Commençons <i className="mdi mdi-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </section>
  	);
  }
}
export default Process;