import React from 'react';
import Footer from '../components/Footer';
import FooterLinks from '../components/FooterLinks';
import SocialMedia from '../components/SocialMedia';
import Aux from '../hoc/Aux_';
import Navbar from '../components/Navbar';

class WebETS extends React.Component {

    section(){
        return(
            <section className="section section-lg">
                <div className="container">
                <div className="realisations-header" style={{marginTop: "1rem"}}>
                   <h1 className='home-title text-rotate gs_reveal gs_reveal_fromTop' style={{color: '#252525', fontSize: "50px"}}>Réalisations</h1>
                   <p style={{fontSize: "30px"}}>
                    Étude de cas :&nbsp;
                    <a href="https://aeroetslab.ca" target="_blank" rel="noopener noreferrer" style={{color: "#30928d"}}>
                       Chaire d'aéropulsion de l'ETS
                    </a>
                  </p>
                </div>
                <div className="row vertical-content">
                <hr className="featurette-divider" style={{"width" : "100%"}}/>
                <div className="row featurette">
                    <div className="col-md-7" >
                        <h2 className="featurette-heading gs_reveal" style={{fontSize: "44px"}}>Modernisation et <span className="text-muted">Cohérence Visuelle</span></h2>
                        <p className="lead">Nous avons modernisé l'identité visuelle de la Chaire de Recherche en Aéropropulsion en créant un nouveau logo, en sélectionnant soigneusement du contenu multimédia et en jouant avec leur palette de couleurs pour rester fidèles à leurs valeurs.
                         Cette approche a permis de renforcer leur image de marque tout en mettant en avant leur position de leader dans la recherche aérospatiale.</p>
                    </div>
                    <div className="col-md-5" style={{"margin": "auto 0"}}>
                    <img src={`${process.env.PUBLIC_URL}/images/local.png`} className="featurette-image img-fluid mx-auto gifs_animation gs_reveal gs_reveal_fromRight" alt="event and corporate, networking video"/>
                    </div>
                </div>

                <hr className="featurette-divider" style={{"width" : "100%"}}/>
                <div className="row featurette">
                    <div className="col-md-7 order-md-2">
                        <h2 className="featurette-heading gs_reveal" style={{fontSize: "44px"}} ><span className="text-muted">Optimisation et </span>Conversion</h2>
                        <p className="lead">L'objectif principal du site étant d'attirer de nouveaux étudiants, nous avons centré notre stratégie analytique sur le suivi des interactions avec le formulaire de contact. Cette focalisation nous permet de mesurer l'efficacité du site dans sa capacité à générer de l'intérêt et à encourager les inscriptions, en ajustant nos stratégies pour maximiser les conversions.</p>
                    </div>
                    <div className="col-md-5 order-md-1 " style={{"margin": "auto 0"}}>
                    <img src={`${process.env.PUBLIC_URL}/images/ets2.jpg`} className="featurette-image img-fluid mx-auto gifs_animation gs_reveal gs_reveal_fromRight" alt="event and corporate, networking video"/>
                    </div>
                </div>

                <hr className="featurette-divider" style={{"width" : "100%"}}/>

                <div className="row featurette">
                    <div className="col-md-7">
                        <h2 className="featurette-heading gs_reveal" style={{fontSize: "44px"}}>Narration Authentique <span className="text-muted">et Visuelle</span></h2>
                        <p className="lead">Nous avons plongé au cœur de l'activité de la Chaire pour raconter leur histoire de manière authentique à travers le site, en restant fidèles à leur essence. En utilisant l'IA pour créer du contenu visuel de pointe et en collaborant étroitement pour la rédaction d'articles via un CMS, nous avons conçu une expérience utilisateur immersive qui reflète véritablement l'innovation et l'excellence de la Chaire en Aéropropulsion.</p>
                    </div>
                    <div className="col-md-5 gifs_animation" style={{"margin": "auto 0"}}>
                    <img src={`${process.env.PUBLIC_URL}/images/ets.png`} className="featurette-image img-fluid mx-auto gifs_animation gs_reveal gs_reveal_fromRight" alt="event and corporate, networking video"/>
                    </div>
                </div>
            </div>
               
                </div>
            </section>
        )
    }
    render() {
        return (
            <Aux>
                <Navbar isGallery={true}/>
                {this.section()}
                {/* Footer Component*/}
                <SocialMedia />
                <Footer />
                {/* FooterLinks Component*/}
                <FooterLinks />
            </Aux>
        )
    }
}

export default WebETS;