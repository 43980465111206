import React from 'react';

class Contact extends React.Component {


  render() {
  	return (
        <section className="section" id="contact">
        <div className="container">
            <div className="row">
                <div className="col-lg-8 offset-lg-2">
                    <h1 className="section-title text-center">Contactez-nous</h1>
                    <div className="section-title-border margin-t-20"></div>
                    <p className="section-subtitle text-muted text-center font-secondary padding-t-30">Prêts à transformer vos idées en réalité ? Remplissez le formulaire ci-dessous et nous vous contacterons au plus vite.</p>
                </div>
            </div>
            <div className="row">
                <div className="col-lg-2">
                    <div className="mt-4 pt-4">
                        <p className="mt-4"><span className="h5">CineStudio</span><br /> <span className="text-muted d-block mt-2">Montréal</span></p>
                    </div>
                </div>
                <div className="col-lg-10">
                    <div className="custom-form mt-4 pt-4">
                        <div id="message"></div>
                        <form method="post" action="home-one" name="contact-form" id="contact-form">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group mt-2">
                                        <input name="name" id="name" type="text" className="form-control" placeholder="Votre nom*" />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group mt-2">
                                        <input name="email" id="email" type="email" className="form-control" placeholder="Votre email*" />
                                    </div>
                                </div>                                
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group mt-2">
                                        <input type="text" className="form-control" id="subject" placeholder="Votre sujet.." />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="form-group mt-2">
                                        <textarea name="comments" id="comments" rows="4" className="form-control" placeholder="Votre message..."></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 text-right">
                                    <input type="submit" id="submit" href="#small-dialog" name="send" className="submitBnt btn btn-custom popup-with-zoom-anim" value="Envoyer" />
                                    <div id="simple-msg"></div>
                                    
                                    <div id="small-dialog" className="zoom-anim-dialog mfp-hide">
                                        <h1>Message envoyé !</h1>
                                        <p>Votre message a été envoyé. Nous vous répondrons dans les plus brefs délais.</p>
                                        <button className='btn btn-custom btn-close'>Fermer</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>  
                </div>
            </div>
        </div>
    </section> 
  	);
  }
}
export default Contact;