import React from 'react';
import { Link } from 'react-router-dom';
import Mailchimp from 'react-mailchimp-form'

class Footer extends React.Component {
  render() {
  	return (
         <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 margin-t-20">
                        <h4>CineStudio</h4>
                        <div className="text-muted margin-t-20">
                            <ul className="list-unstyled footer-list">
                                <li><a href='#home'>Accueil</a></li>
                                <li><a href='#features'>À propos</a></li>
                                <li><a href='#services'>Services</a></li>
                                <li><a href='#team'>L'équipe</a></li>
                                <li><a href='#process'>Processus</a></li>
                            </ul>
                        </div>
                    </div>
                    {/* <div className="col-lg-3 margin-t-20">
                        <h4>Information</h4>
                        <div className="text-muted margin-t-20">
                            <ul className="list-unstyled footer-list">
                                <li><Link to="JavaScript:Void(0);">Terms & Condition</Link></li>
                                <li><Link to="JavaScript:Void(0);">About us</Link></li>
                                <li><Link to="JavaScript:Void(0);">Jobs</Link></li>
                                <li><Link to="JavaScript:Void(0);">Bookmarks</Link></li>
                            </ul>
                        </div>
                    </div> */}
                    <div className="col-lg-6 margin-t-20">
                        <h4>Notre mission</h4>
                        <div className="text-muted margin-t-20">
                            <h3>Nous rendons votre réalité inoubliable</h3>
                            <p>CineStudio est une agence créative digitale située à Montréal. Nous sommes spécalisé dans la production audio-visuel et le marketing numérique.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 margin-t-20">
                        <h4>S'abonner</h4>
                        {/* <div className="text-muted margin-t-20">
                            <p>In an ideal world this text wouldn’t exist, a client would acknowledge the importance of having web copy before the design starts.</p>
                        </div>
                        <form className="form subscribe">
                            <input placeholder="Email" className="form-control" required />
                            <Link to="JavaScript:Void(0);" className="submit"><i className="pe-7s-paper-plane"></i></Link>
                        </form> */}

                        <Mailchimp 
                        action="https://synstudio.us9.list-manage.com/subscribe/post?u=dd940305d6119613e084f8dda&id=00aebf6cbb&f_id=000408e1f0"
                        fields={[
                            {
                                name: 'EMAIL',
                                placeholder: 'Email',
                                type: 'email',
                                required: true
                            }
                        ]}
                        messages = {
                            {button: `S'abonner`}
                        }
                        className='form subscribe' />
                    </div>
                </div>
            </div>
        </footer>
  	);
  }
}
export default Footer;