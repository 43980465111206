import React from 'react';
import Footer from '../components/Footer';
import FooterLinks from '../components/FooterLinks';
import SocialMedia from '../components/SocialMedia';
import Aux from '../hoc/Aux_';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PhotoAlbum from "react-photo-album";
import {photosRealEstate} from "../components/Photos";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
//import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Navbar from '../components/Navbar';

const slides = photosRealEstate.map(({ src, width, height, images }) => ({
    src,
    width,
    height,
    srcSet: images.map((image) => ({
        src: image.src,
        width: image.width,
        height: image.height,
    })),
}));

const videos = [
    {
      title: 'Bien immobilier à Montréal',
      description: `Découvrez ce bien d'exception situé sur la rue Chambord au cœur du Plateau à Montréal, à travers notre vidéo immobilière qui met en lumière son élégance et son charme unique.`,
      director: 'Théo et Arthur',
      production: 'Cinestudio',
      vimeoLink: 'https://www.youtube.com/embed/6zUtF8GMbug?si=pNgmF7uwcsEdVumU', // Replace {video_id} with the actual Vimeo video ID
    },
    {
      title: 'El ConsulMao',
      description: `Plongez dans l'atmosphère envoûtante de l'Hôtel El Consulmao à Minorque à travers notre vidéo, capturant la quintessence de l'hospitalité et du luxe dans un cadre idyllique.`,
      director: 'Théo et Arthur',
      production: 'Cinestudio',
      client: 'El ConsulMao',
      vimeoLink: 'https://www.youtube.com/embed/tIEzkntRYQ4?si=etqfaH_YtEScr6SI', // Replace {video_id} with the actual Vimeo video ID
    }
    // ... add more videos as needed
  ];

class PortfolioRealEstate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          index: -1
        };
      }

    section(){
        return(
            <section className="section section-lg" id="gallery">
                <div className="container">
                <h1 className='home-title text-rotate gs_reveal gs_reveal_fromTop' style={{color: '#252525'}}>Portfolio immobilier</h1>
                <Tabs selectedTabClassName="tabselectStyle">
                    <TabList className="tabStyle ">
                    <Tab className="tabs">Video</Tab>
                    <Tab className="tabs">Photo</Tab>
                    </TabList>

                    <TabPanel>
                        <div>
                            {videos.map((video, index) => (
                                <div key={index} className="video-container">
                                <iframe
                                    title={video.title}
                                    src={video.vimeoLink}
                                    width="640"
                                    height="360"
                                    //frameBorder="0"
                                    sandbox="allow-same-origin allow-scripts"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                                <div>
                                    <h2 className='title'>{video.title}</h2>
                                    <p className='description'>{video.description}</p>
                                    <p><b>Director:</b> {video.director}</p>
                                    <p><b>Production/Post-Production:</b> {video.production}</p>
                                    {video.client && <p><b>Client:</b> {video.client}</p>}
                                </div>
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel>
                    <PhotoAlbum layout="columns" photos={photosRealEstate} onClick={(event, photo, index) => this.setState({ index: index }) } columns={(containerWidth) => {
                        if (containerWidth < 400) return 1;
                        if (containerWidth < 800) return 2;
                        return 3;
                    }}/>
                    <Lightbox
                        slides={slides}
                        open={this.state.index >= 0}
                        index={this.state.index}
                        close={() => this.setState({ index: -1 })}
                        // enable optional lightbox plugins
                        plugins={[ Slideshow, Thumbnails, Zoom]}
                        />
                    </TabPanel>
                </Tabs>
                </div>
            </section>
        )
    }
    render() {
        return (
            <Aux>
                <Navbar isGallery={true}/>
                {this.section()}
                {/* Footer Component*/}
                <SocialMedia />
                <Footer />
                {/* FooterLinks Component*/}
                <FooterLinks />
            </Aux>
        )
    }
}

export default PortfolioRealEstate;