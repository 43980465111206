import React from 'react';
import { createRoot } from 'react-dom/client';
import Home from './Home';
import PortfolioCorpo from './pages/Corpo';
import PortfolioEcom from './pages/CorpoEcom';
import PortfolioTourism from './pages/CorpoTourism';
import PortfolioRealEstate from './pages/CorpoRealEstate';
import PortfolioWeb from './pages/PortfolioWeb';
import * as ServiceWorker from './registerServiceWorker';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import WebETS from './pages/WebETS';
import WebMercure from './pages/WebMercure';

class Root extends React.Component {
  render() {
  	return(
  		<BrowserRouter basename={'/'} >
		  	<Switch>
			  <Route exact path={`${process.env.PUBLIC_URL}/`} component={Home}/>
			  <Route path={`${process.env.PUBLIC_URL}/portfolio-event`} component={PortfolioCorpo}/>
			  <Route path={`${process.env.PUBLIC_URL}/portfolio-tourism`} component={PortfolioTourism}/>
			  <Route path={`${process.env.PUBLIC_URL}/portfolio-ecom`} component={PortfolioEcom}/>
			  <Route path={`${process.env.PUBLIC_URL}/portfolio-real-estate`} component={PortfolioRealEstate}/>
			  <Route path={`${process.env.PUBLIC_URL}/portfolio-web`} component={PortfolioWeb}/>
			  <Route path={`${process.env.PUBLIC_URL}/portfolio-web-ETS`} component={WebETS}/>
			  <Route path={`${process.env.PUBLIC_URL}/portfolio-web-mercure`} component={WebMercure}/>
			</Switch>
		</BrowserRouter>
  	);
  }
 }
const root = createRoot(document.getElementById('root'))
root.render(<Root />);
ServiceWorker.default();
