import React from 'react';
import Navbar from './components/Navbar';
import Services from './components/Services';
import Features from './components/Features';
import Descriptions from './components/Descriptions';
import Team from './components/Team';
import Process from './components/Process';
import Contact from './components/Contact';
import SocialMedia from './components/SocialMedia';
import Footer from './components/Footer';
import FooterLinks from './components/FooterLinks';
import { Link } from 'react-router-dom';
import Aux from './hoc/Aux_';
import Lottie from 'react-lottie';
import animationData from './assets/Lottie/lottie-play.json'
import CookieConsent, {getCookieConsentValue} from "react-cookie-consent";
import { initGA } from './hoc/ga-utils';



const handleAcceptCookie = () => {
      initGA("G-Y6PEH2EZPF");
  };

class Home extends React.Component {
    componentDidMount() {
        const isConsent = getCookieConsentValue();
        if (isConsent === "true") {
          handleAcceptCookie();
        }
      }
    section() {
    var bkg1 = {
        background: "url(images/bg.webp)",
        backgroundSize : 'cover',
        backgroundPosition : 'center',
        };

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
        };

    return (
        <section className="section section-lg height-100vh bg-home" id="home" >
                    <div id="bgg" className="bg-overlay"></div>
                        <div className="display-table">
                            <div className="display-table-cell">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-8 offset-lg-2 text-white text-center">
                                            <h1 className="home-title text-rotate gs_reveal gs_reveal_fromBottom">Nous rendons votre réalité inoubliable</h1>
                                            <p className="padding-t-15 home-desc gs_reveal">CineStudio est une agence créative digitale située à Montréal. Nous sommes spécalisés dans la production audio-visuel et le marketing.</p>
                                            {/* <p className="play-shadow margin-t-30 margin-l-r-auto"> <Link to="https://www.youtube.com/watch?v=DNfW6Qn_ACM"  className="play-btn video-play-icon"><i className="mdi mdi-play text-center"></i></Link></p>    */}
                                            <div className="play-shadow margin-t-30 margin-l-r-auto d-none d-lg-block"> <Link to="https://www.youtube.com/watch?v=DNfW6Qn_ACM"  className="play-btn video-play-icon" style={{"display":'flex', 'alignItems':'center'}}><Lottie options={defaultOptions} height={150} width={150}/></Link></div>   
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </section>
    )
  }

  render() {
  	return (
        <Aux>
                {/* Navbar Component*/}
                <Navbar />
                { this.section() }
                <CookieConsent  enableDeclineButton
                                onAccept={handleAcceptCookie}
                                buttonText="Je comprends"
                                declineButtonText="Je refuse"
                                buttonClasses='btn btn-custom navbar-btn btn-rounded waves-effect waves-light'
                                declineButtonClasses='btn declineButton btn-rounded'
                                buttonStyle={{margin: "10px 15px"}}
                                disableButtonStyles={true}
                                style={{ backgroundColor: "rgb(39, 42, 51)"}}
                >
                    Ce site web utilise des cookies pour améliorer l'expérience utilisateur
                </CookieConsent>

                {/* Features Component*/}
                <Features />

                {/* Services Component*/}
                <Services />
                
                

                {/* Team Component*/}
                <Team />

                {/* Process Component*/}
                <Process />

                {/* Descriptions Component*/}
                <Descriptions />

                {/* Testi Component*/}
                {/* <Testi /> */}

                {/* Started Component*/}

                {/* Blog Component*/}

                {/* Contact Component*/}
                <Contact />

                {/* SocialMedia Component*/}
                <SocialMedia />
                
                {/* Footer Component*/}
                <Footer />

                {/* FooterLinks Component*/}
                <FooterLinks />
        </Aux>
  	);
  }
}

export default Home;