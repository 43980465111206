import React from 'react';
import Footer from '../components/Footer';
import FooterLinks from '../components/FooterLinks';
import SocialMedia from '../components/SocialMedia';
import Aux from '../hoc/Aux_';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PhotoAlbum from "react-photo-album";
import {photosEcom} from "../components/Photos";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
//import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Navbar from '../components/Navbar';

const slides = photosEcom.map(({ src, width, height, images }) => ({
    src,
    width,
    height,
    srcSet: images.map((image) => ({
        src: image.src,
        width: image.width,
        height: image.height,
    })),
}));

const videos = [
    {
      title: 'Pub produit - Demo',
      description: `Version d'une démo reels pour un pitch chez une agence de marketing`,
      director: 'Théo',
      production: 'Cinestudio',
      vimeoLink: 'https://drive.google.com/file/d/1uFsxyLThgS3fxJbVLVgKnLm7acn3R4qD/preview', // Replace {video_id} with the actual Vimeo video ID
    },
    {
      title: 'Pub produit - Demo 2',
      description: `2e version d'une démo reels pour un pitch chez une agence de marketing`,
      director: 'Mathieu',
      production: 'Cinestudio',
      vimeoLink: 'https://drive.google.com/file/d/1a2OVfx1CTBtOlydRWKjHVEC6yE5PM42S/preview', // Replace {video_id} with the actual Vimeo video ID
    },
    {
        title: 'Solee vidéo promotionnelle site web',
        description: 'Une superbe vidéo réalisée en collaboration avec solee',
        director: 'Théo Garnier',
        production: 'Cinestudio',
        client: 'Solee',
        vimeoLink: 'https://drive.google.com/file/d/11HXlCcZnAP-88OleCMleKRClN66IO6wS/preview', // Replace {video_id} with the actual Vimeo video ID
      }
    // ... add more videos as needed
  ];

class PortfolioEcom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          index: -1
        };
      }

    section(){
        return(
            <section className="section section-lg" id="gallery">
                <div className="container">
                <h1 className='home-title text-rotate gs_reveal gs_reveal_fromTop' style={{color: '#252525'}}>Portfolio E-commerce</h1>
                <Tabs selectedTabClassName="tabselectStyle">
                    <TabList className="tabStyle ">
                    <Tab className="tabs">Video</Tab>
                    <Tab className="tabs">Photo</Tab>
                    </TabList>

                    <TabPanel>
                        <div>
                            {videos.map((video, index) => (
                                <div key={index} className="video-container">
                                <iframe
                                    title={video.title}
                                    src={video.vimeoLink}
                                    width="640"
                                    height="360"
                                    //frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    sandbox="allow-same-origin allow-scripts"
                                    allowFullScreen
                                ></iframe>
                                <div>
                                    <h2 className='title'>{video.title}</h2>
                                    <p className='description'>{video.description}</p>
                                    <p><b>Director:</b> {video.director}</p>
                                    <p><b>Production/Post-Production:</b> {video.production}</p>
                                    {video.client && <p><b>Client:</b> {video.client}</p>}
                                </div>
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel>
                    <PhotoAlbum layout="columns" photos={photosEcom} onClick={(event, photo, index) => this.setState({ index: index }) } columns={(containerWidth) => {
                        if (containerWidth < 400) return 1;
                        if (containerWidth < 800) return 2;
                        return 3;
                    }}/>
                    <Lightbox
                        slides={slides}
                        open={this.state.index >= 0}
                        index={this.state.index}
                        close={() => this.setState({ index: -1 })}
                        // enable optional lightbox plugins
                        plugins={[ Slideshow, Thumbnails, Zoom]}
                        />
                    </TabPanel>
                </Tabs>
                </div>
            </section>
        )
    }
    render() {
        return (
            <Aux>
                <Navbar isGallery={true}/>
                {this.section()}
                {/* Footer Component*/}
                <SocialMedia />
                <Footer />
                {/* FooterLinks Component*/}
                <FooterLinks />
            </Aux>
        )
    }
}

export default PortfolioEcom;