import React from 'react';

class Features extends React.Component {
  render() {
  	return (
        <section className="section bg-light" id="features">
        <div className="container">
            <div className="row vertical-content">
                <div className="row featurette">
                    <div className="col-md-7" >
                        <h2 className="featurette-heading gs_reveal">Évenementiel et  <span className="text-muted">Corporatif</span></h2>
                        <p className="lead gs_reveal">Création de contenus visuels captivants pour immortaliser et valoriser vos événements et initiatives d'entreprise.</p>
                        <ul>
                            <li>
                            Couverture d'événement
                            </li>
                            <li>
                            Portraits professionnels
                            </li>
                            <li>
                            Vidéos témoignages
                            </li>
                            <li>
                            Création de contenu pour réseaux sociaux
                            </li>
                        </ul>
                        <a href="/portfolio-event">En savoir plus...</a>
                    </div>
                    <div className="col-md-5" style={{"margin": "auto 0"}}>
                    <video autoPlay loop muted playsInline className="featurette-image img-fluid mx-auto gifs_animation gs_reveal gs_reveal_fromRight" alt="event and corporate, networking video">
                        {/* <source src="gifs/GIF Drone Loop.webm" type='video/webm' /> */}
                        <source src="gifs/GIF Corpo.mp4" type='video/mp4' />
                    </video>
                    </div>
                </div>

                <hr className="featurette-divider" style={{"width" : "100%"}}/>


                <div className="row featurette">
                    <div className="col-md-7 order-md-2">
                        <h2 className="featurette-heading gs_reveal"><span className="text-muted">Tourisme </span>et Aventure</h2>
                        <p className="lead gs_reveal">Contenus dynamiques pour promouvoir destinations et expériences uniques.</p>
                    </div>
                    <div className="col-md-5 order-md-1 " style={{"margin": "auto 0"}}>
                    <video autoPlay loop muted playsInline className="featurette-image img-fluid mx-auto gifs_animation gs_reveal gs_reveal_fromLeft"  alt="drone footage">
                        {/* <source src="gifs/GIF Real Estates.webm" type="video/webm" /> */}
                        <source src="gifs/GIF Drone Loop.mp4" type="video/mp4" />
                    </video>
                    </div>
                </div>

                <hr className="featurette-divider" style={{"width" : "100%"}}/>

                <div className="row featurette">
                    <div className="col-md-7">
                        <h2 className="featurette-heading gs_reveal">Marketing digital <span className="text-muted">et E-commerce</span></h2>
                        <p className="lead gs_reveal">Boostez vos ventes avec des photographies et vidéos de produits percutantes ainsi que des campagnes marketing créatives.</p>
                        <ul>
                            <li>
                            Photos produit
                            </li>
                            <li>
                            Vidéo promotionnelle
                            </li>
                            <li>
                            Campagne marketing
                            </li>
                            <li>
                            Création de contenu
                            </li>
                        </ul>
                        <a href="/portfolio-ecom">En savoir plus...</a>
                    </div>
                    <div className="col-md-5 gifs_animation" style={{"margin": "auto 0"}}>
                        <video autoPlay loop muted playsInline className="featurette-image img-fluid mx-auto gs_reveal gs_reveal_fromRight" alt="ecommerce product video">
                            <source src="gifs/GIF Ecom.mp4" type="video/mp4"/>
                        </video>
                    </div>
                </div>

                <hr className="featurette-divider" style={{"width" : "100%"}}/>


                <div className="row featurette">
                    <div className="col-md-7 order-md-2">
                        <h2 className="featurette-heading gs_reveal"><span className="text-muted">Real</span> Estates</h2>
                        <p className="lead gs_reveal">Contenus dynamiques pour promouvoir destinations et expériences uniques.</p>
                    </div>
                    <div className="col-md-5 order-md-1 " style={{"margin": "auto 0"}}>
                    <video autoPlay loop muted playsInline className="featurette-image img-fluid mx-auto gifs_animation gs_reveal gs_reveal_fromLeft"  alt="real estate image">
                        {/* <source src="gifs/GIF Real Estates.webm" type="video/webm" /> */}
                        <source src="gifs/GIF Real Estates.mp4" type="video/mp4" />
                    </video>
                    </div>
                </div>

            </div>
        </div>
    </section>
  	);
  }
}
export default Features;