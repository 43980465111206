import React from 'react';
import Footer from '../components/Footer';
import FooterLinks from '../components/FooterLinks';
import SocialMedia from '../components/SocialMedia';
import Aux from '../hoc/Aux_';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PhotoAlbum from "react-photo-album";
import {photosCorp} from "../components/Photos";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
//import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Navbar from '../components/Navbar';

const slides = photosCorp.map(({ src, width, height, images }) => ({
    src,
    width,
    height,
    srcSet: images.map((image) => ({
        src: image.src,
        width: image.width,
        height: image.height,
    })),
}));

const videos = [
    {
      title: 'Vidéo promotionnelle AquaEntrepreneur',
      description: `Vidéo promotionnelle créée pour le site web d'AquaAction dans le cadre de leur campagne marketing pour le programme d'AquaEntrepreneur. Conçue pour captiver et inspirer, cette vidéo vise à attirer entrepreneurs et investisseurs à rejoindre un programme dédié à l'innovation dans le secteur de l'eau.`,
      director: 'Théo Garnier and Mathieu Benedetti',
      production: 'Cinestudio',
      vimeoLink: 'https://www.youtube.com/embed/AXPfmE6XBdg?si=KG0JcB83kg0Qedl0',
      client: "AquaAction"
    },
    {
      title: 'AquaHacking Recap',
      description: `Vidéo récapitulative de l'événement AquaHacking d'AquaAction, capturant l'énergie et l'excitation d'une compétition où des entrepreneurs ont présenté leurs projets innovants. Des pitchs passionnants aux moments de la remise des prix, cette vidéo immortalise l'esprit de créativité et de réussite qui caractérise AquaHacking.`,
      director: 'Théo Garnier and Mathieu Benedetti',
      production: 'Cinestudio',
      client: 'AquaAction',
      vimeoLink: 'https://www.youtube.com/embed/qdjquvO9guM?si=UK00kdeEUdy-iipu',
    },
    {
      title: `Célébration d'un an d'innovation avec AquaEntrepreneur`,
      description: `Vidéo récapitulative célébrant le premier anniversaire d'innovation d'impact avec AquaEntrepreneur, tenue à l'aquarium de Québec. Cet événement mémorable, marqué par la visite du ministre Fitzgibbon et de Skeete, a été une vitrine de réussite et d'engagement envers l'innovation durable. Découvrez les moments forts de cette célébration unique à travers notre vidéo.`,
      director: 'Théo Garnier and Mathieu Benedetti',
      production: 'Cinestudio',
      client: 'AquaAction',
      vimeoLink: 'https://www.youtube.com/embed/tpB5x0MiCdM?si=zuHm7B71t-60celg',
    },
    {
      title: `Lancement de la cohorte 2 d'AquaEntrepreneur`,
      description: `Plongez dans le lancement de la cohorte 2 d'AquaEntrepreneur : une vidéo récap qui capture l'esprit d'innovation et la passion des nouveaux projets soutenus par AquaAction`,
      director: 'Théo Garnier and Mathieu Benedetti',
      production: 'Cinestudio',
      client: 'AquaAction',
      vimeoLink: 'https://www.youtube.com/embed/A-lyfUA0Kn8?si=COaMaO0m-sjxooUG',
    },
    {
      title: `Vidéo promotionnelle AquaHacking`,
      description: `Vidéo promotionnelle d'AquaHacking à St-Casimir : un week-end d'expédition et de team bonding, avec visite d'un barrage. Découvrez l'esprit d'équipe et l'aventure qui visent à promouvoir le programme et l'événement.`,
      director: 'Théo Garnier and Mathieu Benedetti',
      production: 'Cinestudio',
      client: 'AquaAction',
      vimeoLink: 'https://www.youtube.com/embed/xB2zFHXghJQ?si=0F7TUcC2XVETYAu5',
    },
    {
      title: `Capsule AquaEntrepreneur`,
      description: `Capsule témoignage d'un AquaEntrepreneur. Réalisée pour promouvoir le programme AquaEntrepreneur sur les réseaux sociaux, cette vidéo vise à inspirer et encourager la participation en dévoilant une histoire personnelle de réussite et d'innovation.`,
      director: 'Théo Garnier and Arthur Garnier',
      production: 'Cinestudio',
      client: 'AquaAction',
      vimeoLink: 'https://youtube.com/embed/J9bvI7Ka9UE?si=OV-MNbEmDU7VLaHy',
    },
    {
        title: `Capsule AquaHacking`,
        description: `Capsule témoignage sur la compétition AquaHacking. Réalisée pour promouvoir l'évènement sur les réseaux sociaux, cette vidéo vise à inspirer et encourager la participation en racontant une expérience personnelle.`,
        director: 'Théo Garnier and Arthur Garnier',
        production: 'Cinestudio',
        client: 'AquaAction',
        vimeoLink: 'https://www.youtube.com/embed/cTfSpzgSCyQ?si=9TCM2AP-3ZcVty0a',
      },
    
    // ... add more videos as needed
  ];

class PortfolioCorpo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          index: -1
        };
      }

    section(){
        return(
            <section className="section section-lg" id="gallery">
                <div className="container">
                <h1 className='home-title text-rotate gs_reveal gs_reveal_fromTop' style={{color: '#252525'}}>Portfolio corporatif et événementiel</h1>
                <Tabs selectedTabClassName="tabselectStyle">
                    <TabList className="tabStyle ">
                    <Tab className="tabs">Video</Tab>
                    <Tab className="tabs">Photo</Tab>
                    </TabList>

                    <TabPanel>
                        <div>
                            {videos.map((video, index) => (
                                <div key={index} className="video-container">
                                <iframe
                                    title={video.title}
                                    src={video.vimeoLink + "&rel=0&showinfo=0&autohide=1"}
                                    width="640"
                                    height="360"
                                    frameBorder="0"
                                    sandbox="allow-same-origin allow-scripts"
                                    allow="autoplay; fullscreen; picture-in-picture; encrypted-media;"
                                    showinfo="0"
                                    controls="0"
                                    autohide="1"
                                    allowFullScreen
                                ></iframe>
                                <div>
                                    <h2  style={{textTransform: "uppercase", letterSpacing: "0.2rem"}} className='title'>{video.title}</h2>
                                    <hr className='featurette-divider' style={{margin: "1.5rem 0", width: "60%"}}/>
                                    <p className='description text-muted'>{video.description}</p>
                                    <p className='text-muted mb-0'><b style={{color: "black", fontWeight: 800}}>Réalisateur:</b> {video.director}</p>
                                    <p className='text-muted mb-0'><b style={{color: "black", fontWeight: 800}}>Production/Post-Production:</b> {video.production}</p>
                                    {video.client && <p className='text-muted mb-0'><b style={{color: "black", fontWeight: 800}}>Client:</b> {video.client}</p>}
                                </div>
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel>
                    <PhotoAlbum layout="columns" photos={photosCorp} onClick={(event, photo, index) => this.setState({ index: index }) } columns={(containerWidth) => {
                        if (containerWidth < 400) return 1;
                        if (containerWidth < 800) return 2;
                        return 3;
                    }}/>
                    <Lightbox
                        slides={slides}
                        open={this.state.index >= 0}
                        index={this.state.index}
                        close={() => this.setState({ index: -1 })}
                        // enable optional lightbox plugins
                        plugins={[ Slideshow, Thumbnails, Zoom]}
                        />
                    </TabPanel>
                </Tabs>
                </div>
            </section>
        )
    }
    render() {
        return (
            <Aux>
                <Navbar isGallery={true}/>
                {this.section()}
                {/* Footer Component*/}
                <SocialMedia />
                <Footer />
                {/* FooterLinks Component*/}
                <FooterLinks />
            </Aux>
        )
    }
}

export default PortfolioCorpo;