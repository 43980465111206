import React from 'react';
import { Link } from 'react-router-dom';

class Descriptions extends React.Component {
  render() {
  	return (
          <section className="section" style={{'paddingBottom':'40px'}}>
                    <div className="col-lg-12 text-center">
                        <h1 className="section-title text-center">Ils nous font confiance</h1>
                        <div className="section-title-border margin-t-20"></div>
                    </div>
                <div className="rowImgs">
                    <div className="columnImgs">
                        <img loading="lazy" src='images/testimonials/ConsulMao.png' style={{"width": '100%', 'height':'auto'}} alt="client 1"/>
                    </div>
                    <div className="columnImgs">
                        <img loading="lazy" src='images/testimonials/Aquaaction.webp'  style={{"width": '100%', 'height':'auto'}} alt="menorca photo" />
                    </div>
                    <div className="columnImgs">
                        <img loading="lazy" src='images/testimonials/ETS.png' style={{"width": '100%', 'height':'auto'}} alt="monaco photo" />
                    </div>
                    <div className="columnImgs">
                        <img loading="lazy" src='images/testimonials/Mercure.png' style={{"width": '100%', 'height':'auto'}} alt="cinque terre photo" />
                    </div>
                </div>
        </section>
  	);
  }
}
export default Descriptions;