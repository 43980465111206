const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];

const photoCorpo = [
    { src: "https://i.ibb.co/MgPCvS3/Aqua-Camp-Day-I-27.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/gPw50rv/Aqua-Camp-Day-I-33.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/d6YF6J5/Aqua-Camp-Day-I-36.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/Rp2JdKR/Aqua-Camp-Day-I-43.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/0s80btN/Aqua-Camp-Day-I-65.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/3dcPL6F/Aqua-Camp-Day-II-15.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/xXy0Dc8/Aqua-Camp-Day-II-64.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/XW587kh/Aqua-Camp-Day-II-65.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/WssSK71/Aqua-Camp-Day-II-96.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/cNtdFxm/Aqua-Hacking-Day-III-5.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/k5b1Xvd/Aqua-Hacking-Day-III-98.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/FxQtHbr/Aqua-Hacking-Day-III-100.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/bXRFV5r/Aqua-Hacking-Day-III-103.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/VSqT7cT/Aqua-Hacking-Day-III-132.jpg", width: 4906, height: 3973 },
    { src: "https://i.ibb.co/hWv99Dq/Aqua-Hacking-Day-III-134.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/w6mMwSk/Castl-59.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/KDdrYxZ/Castl-85.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/pP0G36P/Castl-86.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/wKq2C95/Castl-87.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/rdN8fb6/Castl-111.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/bK1Ck1k/Castl-113.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/THyFtMj/Copy-of-Evenement-Aqua-Action-98.jpg", width: 4905, height: 1879 },
    { src: "https://i.ibb.co/s35ctdz/Copy-of-Photo-Aqua-Action-14-of-21.jpg", width: 5512, height: 3935 },
    { src: "https://i.ibb.co/VT0qV7P/Copy-of-Photo-Aqua-Action-19-of-21.jpg", width: 5973, height: 3982 },
    { src: "https://i.ibb.co/kDTgD9V/Copy-of-Photo-Aqua-Action-05-03-II-27.jpg", width: 5117, height: 4000 },
    { src: "https://i.ibb.co/gTcC382/Copy-of-Photo-Aqua-Action-05-03-30.jpg", width: 5171, height: 4000 },
    { src: "https://i.ibb.co/L8JbvZh/Copy-of-Photo-Aqua-Action-05-03-50.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/s9QPwKR/Copy-of-Photo-Aqua-Action-05-03-52.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/tbY5ycw/Evenement-Aqua-Action-8.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/Kyy4kjw/Photo-Aqua-Action-05-03-II-34.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/DYc57MV/Photo-Aqua-Action-05-03-II-55.jpg", width: 6000, height: 4000 },
];


const photosCorp = photoCorpo.map((photo) => {
    const width = breakpoints[0];
    const height = (photo.height / photo.width) * width;

    return {
        src: photo.src,
        width,
        height,
        images: breakpoints.map((breakpoint) => {
            const height = Math.round((photo.height / photo.width) * breakpoint);
            return {
                src: photo.src,
                width: breakpoint,
                height,
            };
        }),
    };
});


const photoTourism = [
    { src: "https://i.ibb.co/FzFntM5/3valle-es.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/7txzpzD/Avoriaz-5.jpg", width: 3355, height: 1887 },
    { src: "https://i.ibb.co/HBjSfHj/Avoriaz-12.jpg", width: 4019, height: 3012 },
    { src: "https://i.ibb.co/9rdmrRP/Avoriaz-18.jpg", width: 4000, height: 6000 },
    { src: "https://i.ibb.co/fqH9mFw/Cinque-Terre-19.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/c1R6f1t/Cinque-Terre-20.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/5xpdHLL/Cinque-Terre-27.jpg", width: 5705, height: 3803 },
    { src: "https://i.ibb.co/S5NjRz8/Cinque-Terre-29.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/93wGxwS/Cinque-Terre-31.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/8sybGY8/Cinque-Terre-35.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/ChXVG9N/Cinque-Terre-42.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/txyzHyb/Cinque-Terre-51.jpg", width: 5793, height: 3862 },
    { src: "https://i.ibb.co/5sdp76y/Cinque-Terre-55.jpg", width: 5856, height: 3904 },
    { src: "https://i.ibb.co/3pbNdHc/Cinque-Terre-57.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/sQVdGG2/Cinque-Terre-61.jpg", width: 5915, height: 3843 },
    { src: "https://i.ibb.co/7Kf7HgJ/Cinque-Terre-62.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/NyX8K74/Cinque-Terre-63.jpg", width: 3932, height: 5898 },
    { src: "https://i.ibb.co/f41nnVw/Cinque-Terre-74.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/q7dBkwg/Cinque-Terre.jpg", width: 5865, height: 3910 },
    { src: "https://i.ibb.co/vPGj6Vh/Combloux-4.jpg", width: 3599, height: 2024 },
    { src: "https://i.ibb.co/RHHYWPQ/Combloux2-2.jpg", width: 2802, height: 1576 },
    { src: "https://i.ibb.co/2cmzPxw/Contamines-15.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/WtRBFcj/Drone-Pictures-4.jpg", width: 5464, height: 3640 },
    { src: "https://i.ibb.co/KhFW0FY/Drone-Pictures-7.jpg", width: 5464, height: 3640 },
    { src: "https://i.ibb.co/jz5CTkt/Drone-Pictures-9.jpg", width: 5464, height: 3640 },
    { src: "https://i.ibb.co/b2Pcm1n/Drone-Edited-Avoriaz-3.jpg", width: 4024, height: 3016 },
    { src: "https://i.ibb.co/FwWsBFr/Menorca-A7-III-47.jpg", width: 5976, height: 3984 },
    { src: "https://i.ibb.co/ZVyKJ8h/Paris-2.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/0VnJPgW/Paris-29.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/drs7xrc/Phare-Menorca-Drone-HDR-psd.jpg", width: 5443, height: 3944 },
    { src: "https://i.ibb.co/FgYKxcx/Pont-Cham-8.jpg", width: 5267, height: 3944 },
    { src: "https://i.ibb.co/NTJJNns/St-Gervais-5.jpg", width: 5272, height: 2966 },
    { src: "https://i.ibb.co/JdXgt0C/Suisse-31.jpg", width: 4000, height: 6000 },
    { src: "https://i.ibb.co/fN4N7cf/z-Last-Photo-Drone-9.jpg", width: 5464, height: 3640 },
];


const photosTourism = photoTourism.map((photo) => {
    const width = breakpoints[0];
    const height = (photo.height / photo.width) * width;

    return {
        src: photo.src,
        width,
        height,
        images: breakpoints.map((breakpoint) => {
            const height = Math.round((photo.height / photo.width) * breakpoint);
            return {
                src: photo.src,
                width: breakpoint,
                height,
            };
        }),
    };
});

const photoEcom = [
    { src: "https://i.ibb.co/8c0kFZP/Cinestudio-Kalisto-Shooting-2.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/pZFntRP/Cinestudio-Kalisto-Shooting-3.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/Dw16Nn9/Cinestudio-Kalisto-Shooting-4.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/jDNWYyr/Cinestudio-Kalisto-Shooting-5.jpg", width: 5216, height: 3408 },
    { src: "https://i.ibb.co/nBthtnD/SYN00952-beige.png", width: 2640, height: 4000 },
    { src: "https://i.ibb.co/NWqQPvt/Cinestudio-Kalisto-Shooting-7.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/6gSYQ0S/Cinestudio-Kalisto-Shooting-8.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/PMhpMWn/Cinestudio-Kalisto-Shooting-14.jpg", width: 5511, height: 3674 },
    { src: "https://i.ibb.co/4NpkhgS/Cinestudio-Kalisto-Shooting-17.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/b5H0DYw/Cinestudio-Kalisto-Shooting-47.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/tH4xTRG/Cinestudio-Kalisto-Shooting.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/zrMfMTX/Solee-5.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/X531KVb/Solee-7.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/HzXznR2/Solee-8.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/bWtfNWB/Solee-17.jpg", width: 4000, height: 6000 },
    { src: "https://i.ibb.co/HTkMv7W/Solee.jpg", width: 3896, height: 5201 },
    { src: "https://i.ibb.co/xmVtYVZ/Cinestudio-Kalisto-Shooting-6.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/7gVz3LX/SYN00952-coralpink.png", width: 2640, height: 4000 },
    { src: "https://i.ibb.co/sQydhZL/SYN00952-darkblue.png", width: 2640, height: 4000 },
    { src: "https://i.ibb.co/r2chdyP/SYN00952-water.png", width: 2640, height: 4000 },
];

const photosEcom = photoEcom.map((photo) => {
    const width = breakpoints[0];
    const height = (photo.height / photo.width) * width;

    return {
        src: photo.src,
        width,
        height,
        images: breakpoints.map((breakpoint) => {
            const height = Math.round((photo.height / photo.width) * breakpoint);
            return {
                src: photo.src,
                width: breakpoint,
                height,
            };
        }),
    };
});

const photoRealEstate = [
    { src: "https://i.ibb.co/hgrPSnJ/El-Consul-Mao-3.jpg", width: 5623, height: 3749 },
    { src: "https://i.ibb.co/3c9MpRx/El-Consul-Mao-5.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/bd0hhHP/El-Consul-Mao-15.jpg", width: 5911, height: 3941 },
    { src: "https://i.ibb.co/02CxC9p/El-Consul-Mao-16.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/G3S96Sv/El-Consul-Mao-19.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/yndf60f/El-Consul-Mao-29.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/HDMZmtx/El-Consul-Mao-30.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/wC5tpjV/El-Consul-Mao-31.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/rybYmfk/El-Consul-Mao-32.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/5R5PV14/Mao-night.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/Sv56Q9c/Mao-night-5.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/Yfk59G8/Mao-night-6.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/tBLngSk/Mao-night-10.jpg", width: 5914, height: 3943 },
    { src: "https://i.ibb.co/6mqcXJG/Nobel-Photoshoot-21.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/TmyzvQy/Nobel-Photoshoot-39.jpg", width: 4000, height: 6000 },
    { src: "https://i.ibb.co/2MDn6NC/Nobel-Photoshoot-64.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/pnL5FvJ/Nobel-Photoshoot-78.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/6vsG018/Nobel-Photoshoot-79.jpg", width: 5735, height: 3823 },
    { src: "https://i.ibb.co/VMNk2B6/Nobel-Photoshoot-80.jpg", width: 5851, height: 3901 },
    { src: "https://i.ibb.co/qM71DLS/Nobel-Photoshoot-81.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/sy9t82H/photoshoot-4-prise2-46.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/YQj1n3s/Photoshoot-St-Ann-3.jpg", width: 5184, height: 3867 },
    { src: "https://i.ibb.co/6DT1Y2t/Photoshoot-St-Ann-4.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/wct0gt0/SYN06656-HDR.jpg", width: 5555, height: 3963 },
    { src: "https://i.ibb.co/j80rC3M/SYN06868.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/d4ZLmXR/SYN06877.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/MStpZXd/SYN06915.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/Cv5z4FT/Devimco-Photoshoot-2-1.jpg", width: 6000, height: 4000 },
    { src: "https://i.ibb.co/pLNt9FT/Drone-Pictures-12.jpg", width: 5464, height: 3640 },
    { src: "https://i.ibb.co/y5bpVR4/Drone-Pictures-15.jpg", width: 5464, height: 3640 },
]; 

const photosRealEstate = photoRealEstate.map((photo) => {
    const width = breakpoints[0];
    const height = (photo.height / photo.width) * width;

    return {
        src: photo.src,
        width,
        height,
        images: breakpoints.map((breakpoint) => {
            const height = Math.round((photo.height / photo.width) * breakpoint);
            return {
                src: photo.src,
                width: breakpoint,
                height,
            };
        }),
    };
});

export {photosCorp, photosTourism, photosEcom, photosRealEstate};