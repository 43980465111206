import React from 'react';
import Footer from '../components/Footer';
import FooterLinks from '../components/FooterLinks';
import SocialMedia from '../components/SocialMedia';
import Aux from '../hoc/Aux_';
import Navbar from '../components/Navbar';

class PortfolioWeb extends React.Component {
      handleClick = () => {
        // Directly changing the browser's URL
        window.location.href = '/portfolio-web-ETS';
      };
      handleClick2 = () => {
        // Directly changing the browser's URL
        window.location.href = '/portfolio-web-mercure';
      };    

    section(){
        return(
            <section className="section section-lg">
                <div className="container">
                  <div className="realisations-header">
                   <h1 className='home-title text-rotate gs_reveal gs_reveal_fromTop' style={{color: '#252525'}}>Nos réalisations Web</h1>
                    <p style={{fontSize: "23px", marginTop: "1rem"}}>Chez Cinestudio, nous façonnons et renforçons votre identité numérique à travers des sites web moderne et performants. </p>
                    <p style={{fontSize: "23px"}}>En voici quelques-uns!</p>
                  </div>
                  <div className="realisations-content">
                    <div className="column">
                      {/* Contenu de la première colonne */}
                      <div className="realisation-item">
                        <div className='image-container'>
                        <img style={{boxShadow: "1px 0px 5px"}} className='default-image gs_reveal gs_reveal_fromLeft' onClick={this.handleClick} src={`${process.env.PUBLIC_URL}/images/pfweb1.jpg`} alt="website screenshot aeroetslab"/>
                        <img style={{boxShadow: "1px 0px 5px"}} className="hover-image" onClick={this.handleClick} src={`${process.env.PUBLIC_URL}/images/ets.png`} alt="website screenshot aeroetslab hover"/>
                        </div>
                        <h1 className='home-title text-rotate gs_reveal gs_reveal_fromTop'>Aeropropulsion ETS</h1>
                        <p className='description text-muted'>Site web pour la chaire de recherche en Aéropropulsion de l'ETS</p>
                        <button  className='btn btn-custom navbar-btn btn-rounded waves-effect waves-light' onClick={this.handleClick}>Voir plus...</button>
                      </div>
                    </div>
                    <div className="column">
                      {/* Contenu de la deuxième colonne */}
                      <div className="realisation-item">
                      <div className='image-container'>
                        <img style={{boxShadow: "1px 0px 5px"}} className='default-image gs_reveal gs_reveal_fromRight' onClick={this.handleClick2} src={`${process.env.PUBLIC_URL}/images/pfweb2.jpg`} alt="website screenshot mercure conseil" />
                        <img style={{boxShadow: "1px 0px 5px"}} className="hover-image" onClick={this.handleClick2} src={`${process.env.PUBLIC_URL}/images/mercure.png`} alt="website screenshot mercure hover"/>
                      </div>
                        <h1 className='home-title text-rotate gs_reveal gs_reveal_fromTop'>Mercure Conseil</h1>
                        <p className='description text-muted'>Site vitrine de la firme boutique conseil</p>
                        <button  className='btn btn-custom navbar-btn btn-rounded waves-effect waves-light' onClick={this.handleClick2}>Voir plus...</button>
                      </div>
                    </div>
                  </div>

                </div>
            </section>
        )
    }
    render() {
        return (
            <Aux>
                <Navbar isGallery={true}/>
                {this.section()}
                {/* Footer Component*/}
                <SocialMedia />
                <Footer />
                {/* FooterLinks Component*/}
                <FooterLinks />
            </Aux>
        )
    }
}

export default PortfolioWeb;