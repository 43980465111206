import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../assets/Lottie/lottie-video.json'

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

class Services extends React.Component {
    
  render() {
  	return (
        <section className="section pt-5" id="services">
            <div className="container">
                <div className="row" >
                    <div className="col-lg-8 offset-lg-2">
                        <h1 className="section-title text-center gs_reveal">Une collaboration à valeur ajoutée</h1>
                        <div className="section-title-border margin-t-20"></div>
                        <p className="section-subtitle text-muted text-center padding-t-30 font-secondary gs_reveal">Voici les services que nous proposons pour créer de la valeur pour nos clients</p>
                    </div>
                </div>
                <div className='row margin-t-30' style={{"marginBottom":"5vh"}}>
                <div className='col-lg-8'>
                <div className="row margin-t-20" >
                <div className="row margin-t-30">
                    <div className="col-lg-6 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-video text-custom gs_reveal gs_reveal_fromBottom"></i>
                            <h4 className="padding-t-15 gs_reveal">Production Vidéo</h4>
                            <p className="padding-t-15 text-muted gs_reveal">Services complets de production vidéo, de la conception à la post-production, pour créer des contenus visuels impactants.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-photo text-custom gs_reveal gs_reveal_fromBottom"></i>
                            <h4 className="padding-t-15 gs_reveal">Photographie</h4>
                            <p className="padding-t-15 text-muted gs_reveal">Services photographiques professionnels, incluant portraits, photographie de produits, et prises de vue immobilières.</p>
                        </div>
                    </div>
                    
                </div>
                <div className="row margin-t-30">
                    <div className="col-lg-6 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-cart text-custom gs_reveal gs_reveal_fromBottom"></i>
                            <h4 className="padding-t-15 gs_reveal">Stratégie Digitale & Marketing</h4>
                            <p className="padding-t-15 text-muted gs_reveal">Accompagnement dans la définition et l'implémentation de stratégies de contenu digital, y compris pour les réseaux sociaux.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 margin-t-20">
                        <div className="services-box text-center hover-effect">
                            <i className="pe-7s-global text-custom gs_reveal gs_reveal_fromBottom"></i>
                            <h4 className="padding-t-15 gs_reveal">Site Web</h4>
                            <p className="padding-t-15 text-muted gs_reveal">Conception et développement de sites web optimisés pour renforcer la présence en ligne de votre entreprise ou marque.</p>
                            <a style={{color: "#30928d"}} href="/portfolio-web">Voir nos réalisations</a>
                        </div>
                    </div>
                    
                </div>
                
                </div>
                </div>
                <div className="col-lg-4 margin-t-20 gs_reveal gs_reveal_fromRight" style={{"alignSelf":"center"}}>
                    <div>
                    <Lottie options={defaultOptions} height={400} width={400}/>
                    </div>
                </div>
                </div>
                
            </div>
        </section>
  	);
  }
}
export default Services;