import React from 'react';

class Team extends React.Component {
  render() {
  	return (
     <section className="section pt-4" id="team">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <h1 className="section-title text-center">Qui sommes nous</h1>
                        <div className="section-title-border margin-t-20"></div>
                    </div>
                </div>
                <div className="row margin-t-50">
                    <div className="col-lg-2 col-sm-6">
                        
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="team-box text-center hover-effect">
                            <div className="team-wrapper">
                                <div className="team-member">
                                    <img alt="member 1" src="images/team/Theo.webp" className="img-fluid rounded gs_reveal gs_reveal_fromLeft" />
                                </div>
                            </div>
                            <h4 className="team-name gs_reveal">Théo Garnier</h4>
                            <p className="text-uppercase team-designation gs_reveal">CO-FONDATEUR - Master E-Commerce - HEC</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="team-box text-center hover-effect">
                            <div className="team-wrapper">
                                <div className="team-member">
                                    <img alt="member 2" src="images/team/Arthur.webp" className="img-fluid rounded gs_reveal gs_reveal_fromRight" />
                                </div>
                            </div>
                            <h4 className="team-name gs_reveal">Arthur Garnier</h4>
                            <p className="text-uppercase team-designation gs_reveal">CO-FONDATEUR - Ingénieur Logiciel</p>
                        </div>
                    </div>

                    <div className="col-lg-2 col-sm-6">
                        
                    </div>

                </div>
            </div>
        </section>
  	);
  }
}
export default Team;