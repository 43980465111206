import React from 'react';
import { ReactComponent as MyLogo } from '../assets/logo.svg';


class Navbar extends React.Component {


    handleClick = () => {
        // Directly changing the browser's URL
        window.location.href = '/portfolio-event';
      };
      handleClick2 = () => {
        // Directly changing the browser's URL
        window.location.href = '/portfolio-tourism';
      };
      handleClick3 = () => {
        // Directly changing the browser's URL
        window.location.href = '/portfolio-real-estate';
      };
      handleClick4 = () => {
        // Directly changing the browser's URL
        window.location.href = '/portfolio-ecom';
      };
      handleClick5 = () => {
        // Directly changing the browser's URL
        window.location.href = '/portfolio-web';
      };

    scrollTo = (id) => {
        if(document.getElementById(id)){
            document.getElementById(id).scrollIntoView();
        }
    }
    

  render() {

    const navbarStyle = {
        backgroundColor: this.props.isGallery ? '#272a33' : '' // Apply black if isGallery is true
      };

    const linkClassName = `nav-item ${this.props.isGallery ? 'active' : ''}`
    const linkClassNameRev = `nav-item ${this.props.isGallery ? '' : 'active'}`

  	return (
        <nav className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" style={navbarStyle}>
            <div className="container">
                <div className="navbar-brand logo text-uppercase" onClick={() => this.props.isGallery ? window.location.href = "/" : this.scrollTo('home')}>
                    <MyLogo className="logo-svg" width="15rem" fill="white"/>
                </div>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <i className="mdi mdi-menu"></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <ul className="navbar-nav navbar-center" id="mySidenav">
                        <li className={linkClassNameRev}>
                            { this.props.isGallery ?
                                <a onClick={() => window.location.href = "/"} className="nav-link">Accueil</a>
                                :
                                <a href="#home" className="nav-link">Accueil</a>
                            }
                        </li>
                        <li className="nav-item">
                            { this.props.isGallery ?
                                <a onClick={() => window.location.href = "/" } className="nav-link">Nos Axes</a>
                                :
                                <a href="#features" className="nav-link">Nos Axes</a>
                            }
                        </li>
                        <li className="nav-item">
                            
                            { this.props.isGallery ?
                                <a onClick={() => window.location.href = "/"} className="nav-link">Services</a>
                                :
                                <a href="#services" className="nav-link">Services</a>
                            }
                        </li>
                        <li className="nav-item">                            
                            { this.props.isGallery ?
                                <a onClick={() => window.location.href = "/"} className="nav-link">L'équipe</a>
                                :
                                <a href="#team" className="nav-link">L'équipe</a>
                            }
                        </li>
                        <li className="nav-item">                            
                            { this.props.isGallery ?
                                <a onClick={() => window.location.href = "/"} className="nav-link">Processus</a>
                                :
                                <a href="#process" className="nav-link">Processus</a>
                            }
                        </li>
                        <li className="nav-item"> 
                            { this.props.isGallery ?
                                <a onClick={() => window.location.href = "/"} className="nav-link">Contact</a>
                                :
                                <a href="#contact" className="nav-link">Contact</a>
                            }
                        </li>
                        <li className={linkClassName}>
                            <div className="dropdown show">
                                <a className="dropdown-toggle nav-link" style={{paddingRight: 0, cursor: 'pointer'}} role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Portfolio
                                </a>

                                <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                    <button type="button" className="dropdown-item" onClick= {this.handleClick}>Événementiel</button>
                                    <button type="button" className="dropdown-item" onClick= {this.handleClick2}>Tourisme</button>
                                    <button type="button" className="dropdown-item" onClick= {this.handleClick4}>E-Commerce</button>
                                    <button type="button" className="dropdown-item" onClick= {this.handleClick3}>Immobilier</button>
                                    <button type="button" className="dropdown-item" onClick= {this.handleClick5}>Site Web</button>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div className="nav-button ml-auto">
                        <ul className="nav navbar-nav navbar-right">
                            <li>
                                <button type="button" onClick={() => this.scrollTo('contact')} className="btn btn-custom navbar-btn btn-rounded waves-effect waves-light">Contactez-nous</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </nav>
  	);
  }
}

export default Navbar;