import React from 'react';
import Footer from '../components/Footer';
import FooterLinks from '../components/FooterLinks';
import SocialMedia from '../components/SocialMedia';
import Aux from '../hoc/Aux_';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import PhotoAlbum from "react-photo-album";
import {photosTourism} from "../components/Photos";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
//import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import Navbar from '../components/Navbar';

const slides = photosTourism.map(({ src, width, height, images }) => ({
    src,
    width,
    height,
    srcSet: images.map((image) => ({
        src: image.src,
        width: image.width,
        height: image.height,
    })),
}));

const videos = [
    {
      title: 'Getaway in Menorca',
      description: `This summer we went on the discovery of the beautiful island of Menorca, in the baleares island. We had the opportunity to work with the hotel boutique El Consul Mao for their grand opening in July 2022. Big thanks to them for this opportunity. Hopefully we will be back to Menorca anytime soon. Hope you enjoy this video as much as we enjoyed making it!`,
      director: 'Arthur et Théo',
      production: 'Cinestudio',
      vimeoLink: 'https://www.youtube.com/embed/DNfW6Qn_ACM?si=mla2oSWfaEjSg73T', // Replace {video_id} with the actual Vimeo video ID
    },
    {
      title: 'Colombie-Britannique - Hiver 2021',
      description: `Last winter my brother and I joined our friend Gilian in the canadian west coast for a roadtrip across all the British Columbia.
      We skied in Whistler, Kamloops (Sun Peak), Revelstoke, Golden (Kicking Horse), Banff & Lake Louise.
      Here is the best moment of it. Hope you enjoy!`,
      director: 'Arthur, Théo et Gilian',
      production: 'Cinestudio',
      vimeoLink: 'https://www.youtube.com/embed/hqAYnkD5Q0w?si=YRTS__rHgcqq3EdH', // Replace {video_id} with the actual Vimeo video ID
    },
    {
      title: 'Tulum - Été 2021',
      description: 'Last summer with some friends in Tulum...',
      director: 'Arthur et Théo',
      production: 'Cinestudio',
      vimeoLink: 'https://www.youtube.com/embed/iFkoOADRqL0?si=w3V95yuevcMX0Xmp', // Replace {video_id} with the actual Vimeo video ID
    },
    {
      title: 'CANADA: GASPESIE - Drone & Timelapse in 4K',
      description: '4 days in Gaspesie lived through Drones and Timelapse...',
      director: 'Arthur et Théo',
      production: 'Cinestudio',
      vimeoLink: 'https://www.youtube.com/embed/u821k8QVMt8?si=gTV0RjQkFcN-UkY3', // Replace {video_id} with the actual Vimeo video ID
    },
    {
        title: 'Cinestudio x Pasderoche - Escapade Sonore Teaser',
        description: 'Teaser Escapade sonore pour Pasderoche',
        director: 'Théo',
        production: 'Cinestudio',
        vimeoLink: 'https://www.youtube.com/embed/RVTuUtke4jI?si=Htla-T-jaFoFzlid', // Replace {video_id} with the actual Vimeo video ID
      }
    // ... add more videos as needed
  ];

class PortfolioTourism extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          index: -1
        };
      }

    section(){
        return(
            <section className="section section-lg" id="gallery">
                <div className="container">
                <h1 className='home-title text-rotate gs_reveal gs_reveal_fromTop' style={{color: '#252525'}}>Portfolio tourisme et voyage</h1>
                <Tabs selectedTabClassName="tabselectStyle">
                    <TabList className="tabStyle ">
                    <Tab className="tabs">Video</Tab>
                    <Tab className="tabs">Photo</Tab>
                    </TabList>

                    <TabPanel>
                        <div>
                            {videos.map((video, index) => (
                                <div key={index} className="video-container">
                                <iframe
                                    title={video.title}
                                    src={video.vimeoLink}
                                    width="640"
                                    height="360"
                                    sandbox="allow-same-origin allow-scripts"
                                    //frameBorder="0"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                                <div>
                                    <h2 className='title'>{video.title}</h2>
                                    <p className='description'>{video.description}</p>
                                    <p><b>Director:</b> {video.director}</p>
                                    <p><b>Production/Post-Production:</b> {video.production}</p>
                                    {video.client && <p><b>Client:</b> {video.client}</p>}
                                </div>
                                </div>
                            ))}
                        </div>
                    </TabPanel>
                    <TabPanel>
                    <PhotoAlbum layout="columns" photos={photosTourism} onClick={(event, photo, index) => this.setState({ index: index }) } columns={(containerWidth) => {
                        if (containerWidth < 400) return 1;
                        if (containerWidth < 800) return 2;
                        return 3;
                    }}/>
                    <Lightbox
                        slides={slides}
                        open={this.state.index >= 0}
                        index={this.state.index}
                        close={() => this.setState({ index: -1 })}
                        // enable optional lightbox plugins
                        plugins={[ Slideshow, Thumbnails, Zoom]}
                        />
                    </TabPanel>
                </Tabs>
                </div>
            </section>
        )
    }
    render() {
        return (
            <Aux>
                <Navbar isGallery={true}/>
                {this.section()}
                {/* Footer Component*/}
                <SocialMedia />
                <Footer />
                {/* FooterLinks Component*/}
                <FooterLinks />
            </Aux>
        )
    }
}

export default PortfolioTourism;