import React from 'react';
import Footer from '../components/Footer';
import FooterLinks from '../components/FooterLinks';
import SocialMedia from '../components/SocialMedia';
import Aux from '../hoc/Aux_';
import Navbar from '../components/Navbar';

class WebMercure extends React.Component {

    section(){
        return(
            <section className="section section-lg">
                <div className="container">
                <div className="realisations-header" style={{marginTop: "1rem"}}>
                   <h1 className='home-title text-rotate gs_reveal gs_reveal_fromTop' style={{color: '#252525', fontSize: "50px"}}>Réalisations</h1>
                   <p style={{fontSize: "30px"}}>
                    Étude de cas :&nbsp;
                    <a href="https://mercureconseil.ca" target="_blank" rel="noopener noreferrer" style={{color: "#30928d"}}>
                       Mercure Conseil
                    </a>
                  </p>
                </div>
                <div className="row vertical-content">
                <hr className="featurette-divider" style={{"width" : "100%"}}/>
                <div className="row featurette">
                    <div className="col-md-7" >
                        <h2 className="featurette-heading gs_reveal" style={{fontSize: "44px"}}>Contenu multimédia <span className="text-muted">personnalisé</span></h2>
                        <p className="lead">Pour Mercure Conseil, nous avons orchestré une session de tournage et de shooting photo sur mesure, capturant l'essence de leur marque et de leur expertise. Ce contenu multimédia personnalisé a été soigneusement conçu pour s'intégrer harmonieusement au design du site web, offrant une expérience visuelle riche et engageante aux visiteurs. Notre approche a permis de mettre en valeur les services uniques de Mercure Conseil, en soulignant leur professionnalisme et leur innovation.</p>
                    </div>
                    <div className="col-md-5" style={{"margin": "auto 0"}}>
                    <video autoPlay loop muted playsInline className="featurette-image img-fluid mx-auto gifs_animation gs_reveal gs_reveal_fromLeft"  alt="real estate image">
                        {/* <source src="gifs/GIF Real Estates.webm" type="video/webm" /> */}
                        <source src="gifs/home-mobile.mp4" type="video/mp4" />
                    </video>
                    </div>
                </div>

                <hr className="featurette-divider" style={{"width" : "100%"}}/>
                <div className="row featurette">
                    <div className="col-md-7 order-md-2">
                        <h2 className="featurette-heading gs_reveal" style={{fontSize: "44px"}} ><span className="text-muted">Collaboration et </span>Copywriting</h2>
                        <p className="lead">En collaboration étroite avec l'équipe de Mercure Conseil, nous avons développé un contenu textuel captivant et optimisé pour le SEO. Grâce à un processus de copywriting efficace, nous avons articulé la vision et les valeurs de Mercure Conseil, tout en assurant une haute visibilité sur les moteurs de recherche. Cette démarche collaborative a permis de créer un contenu authentique qui parle à leur audience cible, renforçant ainsi l'engagement et la présence en ligne de la marque.</p>
                    </div>
                    <div className="col-md-5 order-md-1 " style={{"margin": "auto 0"}}>
                    <img src={`${process.env.PUBLIC_URL}/images/Mercure Conseil BTS.jpg`} className="featurette-image img-fluid mx-auto gifs_animation gs_reveal gs_reveal_fromRight" alt="event and corporate, networking video"/>
                    </div>
                </div>

                <hr className="featurette-divider" style={{"width" : "100%"}}/>

                <div className="row featurette">
                    <div className="col-md-7">
                        <h2 className="featurette-heading gs_reveal" style={{fontSize: "44px"}}>Adaptation à la <span className="text-muted">Technologie</span></h2>
                        <p className="lead">La refonte du site web de Mercure Conseil nous a confrontés à la nécessité de nous adapter à leur stack technologique existante et à leur système de déploiement spécifique. En travaillant étroitement avec leur équipe technique, nous avons réussi à intégrer de nouvelles fonctionnalités et un design rafraîchi tout en préservant l'intégrité du système en place. Cette étape cruciale a assuré une transition fluide et efficace vers le nouveau site, sans perturber l'expérience utilisateur ni les opérations quotidiennes de Mercure Conseil.</p>
                    </div>
                    <div className="col-md-5 gifs_animation" style={{"margin": "auto 0"}}>
                    <img src={`${process.env.PUBLIC_URL}/images/mercure.png`} className="featurette-image img-fluid mx-auto gifs_animation gs_reveal gs_reveal_fromRight" alt="event and corporate, networking video"/>
                    </div>
                </div>
            </div>
               
                </div>
            </section>
        )
    }
    render() {
        return (
            <Aux>
                <Navbar isGallery={true}/>
                {this.section()}
                {/* Footer Component*/}
                <SocialMedia />
                <Footer />
                {/* FooterLinks Component*/}
                <FooterLinks />
            </Aux>
        )
    }
}

export default WebMercure;